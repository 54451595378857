<template>
  <div class="photo-upload-container">
    <div v-if="accessUrls.length < 1" class="corners photo-example-container">
      <div
        class="photo-example"
        :style="{ backgroundImage: 'url(' + exampleImageURL + ')' }"
      ></div>
    </div>
    <div v-else class="photo-previews">
      <div v-for="url in accessUrls" class="preview" :key="url.id">
        <div class="delete-icon" @click="deleteImage(url)">
          <i class="fa fa-times" />
        </div>
        <img :src="url.accessUrl" />
      </div>
    </div>
    <div class="photo-controls">
      <div
        class="uploader"
        :class="{ 'uploader-fullwidth': accessUrls.length < 1 }"
      >
        <image-uploader
          :debug="0"
          :max-width="1250"
          :quality="0.8"
          :auto-rotate="true"
          output-format="blob"
          :preview="false"
          :class-name="['fileinput']"
          capture="environment"
          accept="image/*"
          @onUpload="startTimer"
          @input="uploadPhoto"
        >
          <label for="fileInput" slot="upload-label">
            <div class="button primary" style="min-width:67px;">
              <span v-if="uploadInProgress"
                ><i
                  class="fa fa-spinner
              fa-spin"
                />
                {{ action }}</span
              >
              <span v-else-if="accessUrls.length < 1">{{
                $t("general.continue")
              }}</span>
              <span v-else><i class="fa fa-plus"/></span>
            </div>
          </label>
        </image-uploader>
      </div>
      <div class="proceed" v-if="accessUrls.length > 0">
        <div
          class="button primary"
          :disabled="uploadInProgress"
          @click="next()"
        >
          {{ $t("photoUpload.continue") }}
        </div>
      </div>
    </div>
    <div v-if="error">
      {{ $t("photoUpload.status", { status }) }}<br />
      {{ $t("photoUpload.error", { error }) }}
    </div>
    <div v-if="$isAdmin" class="photo-next">
      <button
        class="button secondary"
        :disabled="uploadInProgress"
        @click="next()"
      >
        Admin skip
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PhotoUpload",
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uploadInProgress: false,
      error: null,
      status: "",
      uploadComplete: false,
      accessUrls: [],
      processStart: 1,
      action: null
    };
  },
  methods: {
    startTimer() {
      this.uploadInProgress = true;
      this.processStart = performance.now();
      this.action = "processing";
    },
    async uploadPhoto(img) {
      let t0 = performance.now();
      this.action = "uploading";
      this.uploadInProgress = true;

      let timestamp = Math.floor(Date.now() / 1000);
      const filename = this.question.uid + "-" + timestamp;
      let file = new File([img], filename, { type: img.type });

      const uploadUrl = `${process.env.VUE_APP_IMAGE_SERVICE_URL}/imageService`;
      const response = await axios.post(uploadUrl, { fileName: filename });
      const bucketUploadUrl = response.data.uploadUrl;
      const accessUrl = response.data.accessUrl;
      const imageId = response.data._id;

      const xhr = new XMLHttpRequest();
      xhr.open("PUT", bucketUploadUrl, true);
      xhr.onload = async () => {
        const status = xhr.status;
        if (status === 200) {
          this.status = "upload complete";
          this.uploadComplete = true;
          this.uploadInProgress = false;
          this.accessUrls.unshift({ id: imageId, accessUrl: accessUrl }); // unshift with css direction rtl to scroll right automatically
        } else {
          this.status = "an error occured";
          this.error = status;
        }
      };

      xhr.onerror = err => {
        this.status = "an error occured";
        this.error = err;
        this.uploadInProgress = false;
      };
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);

      let t1 = performance.now();
      let message =
        "*DEBUG*: " +
        this.$username +
        " | " +
        filename +
        "\nProcessing     took: " +
        (t0 - this.processStart) +
        "\nUpload of Foto took: " +
        (t1 - t0);
      this.$log("debug", message);

      console.dir(this.question.uid);
      if (
        this.question &&
        (this.question.uid == "registration_document_front_photo" ||
          this.question.uid == "vehicle_picture_rightside")
      ) {
        this.$logDebug(
          "*" +
            this.assID +
            "*: first or second picture in new assessment \n" +
            message
        );
      }

      this.action = null;
    },
    deleteImage(accessUrlObject) {
      let idx = this.accessUrls.findIndex(a => accessUrlObject.id === a.id);
      this.accessUrls.splice(idx, 1);
    },
    reset() {
      this.uploadComplete = false;
      this.error = null;
      this.status = "";
      this.accessUrls = [];
    },
    next() {
      this.$emit("widget-event", {
        type: "photo",
        questionUid: this.question.uid,
        optionUid: this.accessUrls.map(a => a.id)
      });
      this.reset();
    }
  },
  watch: {
    question() {
      this.uploadInProgress = false;
      this.error = null;
      this.status = "";
      this.uploadComplete = false;
      this.accessUrl = null;
    }
  },
  computed: {
    assID() {
      return this.$store.getters.assID;
    },
    exampleImageURL() {
      return (
        this.question.exampleImageURL ||
        "https://storage.googleapis.com/truckoo-assets/inspection-app/Camera%20Adjustment%20Icon%20200x200.png"
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/shared.scss";

.photo-upload-container {
  padding: 20px;
}

.photo-example-container {
  margin-bottom: 20px;
}

.photo-example {
  margin: 10px;
  height: 180px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

[type="file"] {
  display: none;
}

.photo-previews {
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 10px;
  direction: rtl;
  padding: 20px 0px;

  .preview {
    display: inline-block;
    height: 300px;
    margin: 0px 20px;
    position: relative;

    img {
      border: 2px solid white;
      max-height: 100%;
      // max-width: 100%;
    }

    .delete-icon {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      background-color: white;
      color: $primary;
    }
  }
}

.photo-controls {
  display: flex;

  .proceed {
    flex: 1;
    margin-left: 30px;
  }

  .uploader-fullwidth {
    flex: 1;
  }
}

.photo-next {
  margin-top: 30px;
}
</style>
