<template>
  <div class="scroll-input-container">
    <scroll-picker
      :options="scrollOptions"
      v-model="selectedOption"
    /> 
    <div class="next">
      <div 
        class="button primary" 
        @click="next()"
      >
        {{ $t('general.continue')}}
      </div>
    </div>
  </div>
</template>

<script>
import ScrollPicker from './scroll-picker/ScrollPicker.js'

export default {
  name: 'ScrollInput',
  components: {
    ScrollPicker
  },
  props: {
    question: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selectedOption: "",
    }
  },
  methods: {
    next() {
      let emissionPayload = {
        type: 'scroll-input',
        questionUid: this.question.uid,
        optionUid: this.selectedOption
      }
      
      this.$emit('widget-event', emissionPayload)
    },
  },
  computed: {
    scrollOptions(){
      return this.question.options.map(o => { return {"name": o.label, "value": o.uid}})
    },
  },
  watch: {
    question() {
      this.selectedOption = ''
    },
    scrollOptions: {
      immediate: true,
      handler() {
        if(this.scrollOptions.length > 0) {
          let defaultSelectionIdx = Math.floor(this.scrollOptions.length/2)
          this.selectedOption = this.scrollOptions[defaultSelectionIdx].value
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/shared.scss';
.scroll-input-container {
  font-size: 30px;
  font-weight: bold;
  // width: 300px;
  // max-width: 70vw;
  // height: 300px;
  margin: auto;
  width: 100%;
}
.vue-scroll-picker-item-placeholder.-selected {
  color: $highlighted;
}
.vue-scroll-picker-item.-selected {
  color: $highlighted;
}
.vue-scroll-picker-layer {

  .top {
    background: linear-gradient(180deg, $primary 10%, rgba($primary, 0.7));
    border-bottom: none;
  }
  .bottom {
    background: linear-gradient(180deg, rgba($primary, 0.7) 10%, $primary);
    border-top: none;
  }
  .middle {
    @include corners();
  }
}
</style>
