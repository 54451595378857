<template>
  <div class="multi-select-container">
    <div 
      v-for="o in question.options" 
      :key="o.uid" 
      @click="updateSelection(o)" 
      class="button secondary option"
      :class="{ 'selected': optionIsSelected(o) }"
    >
      {{ o.label }}
    </div>
    <div class="next">
      <div 
        class="button primary" 
        @click="next()"
      >
        <span v-if="this.selection.length > 0">
          {{ $t('general.continue') }}
        </span>
        <span v-else>
          {{ $t('multiSelect.none') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    question: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selection: []
    }
  },
  methods: {
    updateSelection(o) {
      if (this.optionIsSelected(o)) {
        console.log('already selected')
        console.log(this.selection.map(elt => elt.uid))
        this.selection = this.removeFromArray(this.selection, o)
        console.log(this.selection.map(elt => elt.uid))
      }
      else {
        this.selection.push(o)
      }
    },
    next() {
      this.$emit('widget-event', { 
        type: 'multi-select', 
        questionUid: this.question.uid, 
        optionUid: this.selection.map(elt => elt.uid).sort().join(',') 
      })
      this.reset()
    },
    reset() {
      this.selection = []
    },
    optionIsSelected(option){
      return this.selection.map(selectedOption => selectedOption.uid)
        .includes(option.uid)
    },
    removeFromArray(arr, option) {
      return arr.filter(element => element.uid != option.uid)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.option {
  margin: 20px 0px;
}
</style>
