<template>
  <div class="single-select-container">
    <div 
      v-for="o in question.options" 
      :key="o.uid" 
      @click="selected(o)" 
      class="button primary option"
    >
      {{ o.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSelect',
  props: {
    question: {
      type: Object,
      required: true,
    }
  },
  methods: {
    selected(o) {
      this.$emit('widget-event', { type: 'single-select', questionUid: this.question.uid, questionLabel: this.question.label, optionUid: o.uid, optionLabel: o.label })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.option {
  margin: 20px 0px;
}
</style>
