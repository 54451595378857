<template>
  <div class="answer-missing-container">
    <div class="icon" @click="showOverlay = true">
      {{ $t("answerMissing.icon") }}
    </div>

    <div v-if="showOverlay" class="modal-overlay">
      <div class="modal-content answer-missing">
        <div class="header">
          {{ $t("answerMissing.header") }}
        </div>
        <div class="content">
          <textarea
            class="input"
            v-model="feedback"
            style="resize:none;"
            rows="3"
          />
        </div>
        <div class="controls">
          <div class="button secondary" @click="showOverlay = false">
            {{ $t("answerMissing.cancel") }}
          </div>
          <div
            class="button primary"
            :class="{ disabled: !inputValid }"
            @click="sendFeedback()"
          >
            {{ $t("general.continue") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slackService from "@/services/slack-service.js";

export default {
  name: "AnswerMissing",
  props: {
    questionUID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      feedback: "",
    };
  },
  methods: {
    sendFeedback() {
      if (!this.inputValid) {
        return;
      }
      console.log(this.$username, this.questionUID, this.feedback);
      slackService.sendAnswerMissingFeedback(
        this.$username,
        this.questionUID,
        this.feedback
      );
      this.$emit("skip-question");
      this.showOverlay = false;
      this.feedback = "";
    },
  },
  computed: {
    inputValid() {
      return this.feedback.trim() != "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/shared.scss";

.icon {
  text-align: right;
  font-size: 14px;
  color: $lightText;
  padding: 0px 10px;
}

.answer-missing {
  background-color: white;
  width: 80vw;
  color: $darkText;
  font-size: 20px;
  padding: 30px;
  box-sizing: border-box;

  .content {
    padding: 20px 0px;
    .input {
      background-color: white;
      border: 2px solid $primary;
      border-radius: 0;
    }
  }

  .controls {
    position: relative;
    top: 80px;

    div {
      margin-bottom: 20px;
      border: none;
    }
  }
}
</style>
