<template>
  <div class="scroll-range-input-container">
    <scroll-picker
      :options="scrollOptions"
      v-model="selectedOption"
    /> 
    <div class="next">
      <div 
        class="button primary" 
        @click="next()"
      >
        {{ $t('general.continue')}}
      </div>
    </div>
  </div>
</template>

<script>
import ScrollPicker from './scroll-picker/ScrollPicker.js'

export default {
  name: 'ScrollRangeInput',
  components: {
    ScrollPicker
  },
  props: {
    question: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      selectedOption: "",
    }
  },
  methods: {
    next() {
      this.$emit('widget-event', { type: 'scroll-range-input', questionUid: this.question.uid, optionUid: this.selectedOption })
    },
  },
 computed: {
    scrollOptions() {
      let options = []

      let decimals = this.question.rangeWidget.decimals
      if (decimals == undefined)
        decimals = 0

      for(let i = +this.question.rangeWidget.startValue;
              i <= +this.question.rangeWidget.endValue;
              i += +this.question.rangeWidget.stepSize) {
        let r = i.toFixed(decimals)
        options.push({ "name": r + ' ' + this.question.rangeWidget.unit, "value": i })
      }
      return options
    },
  },
  watch: {
    question: {
      handler() {
        this.selectedOption = this.question.rangeWidget.default || this.question.rangeWidget.startValue
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/shared.scss';

.scroll-range-input-container {
  font-size: 30px;
  font-weight: bold;
  // width: 300px;
  // max-width: 70vw;
  // height: 300px;
  margin: auto;
  width: 100%;
}
.vue-scroll-picker-item-placeholder.-selected {
  color: $highlighted;
}
.vue-scroll-picker-item.-selected {
  color: $highlighted;
}
.vue-scroll-picker-layer {

  .top {
    background: linear-gradient(180deg, $primary 10%, rgba($primary, 0.7));
    border-bottom: none;
  }
  .bottom {
    background: linear-gradient(180deg, rgba($primary, 0.7) 10%, $primary);
    border-top: none;
  }
  .middle {
    @include corners();
  }
}
</style>
