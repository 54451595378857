<template>
  <div class="number-input-container">
    <div class="corners input-container">
      <div class="dummy-input">
        {{ formattedInput }}
      </div>
    </div>
    <div class="placeholder-dummy" style="height: 250px;"></div>
    <div class="numpad">
      <div class="row">
        <div class="element" @click="append('1')">1</div>
        <div class="element" @click="append('2')">2</div>
        <div class="element" @click="append('3')">3</div>
      </div>
      <div class="row">
        <div class="element" @click="append('4')">4</div>
        <div class="element" @click="append('5')">5</div>
        <div class="element" @click="append('6')">6</div>
      </div>
      <div class="row">
        <div class="element" @click="append('7')">7</div>
        <div class="element" @click="append('8')">8</div>
        <div class="element" @click="append('9')">9</div>
      </div>
      <div class="row">
        <div class="element" @click="backspace()"><i class="fa fa-backspace"/></div>
        <div class="element" @click="append('0')">0</div>
        <div class="element proceed" @click="next()">{{ buttonLabel }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberInput',
  props: {
    uid: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true,
    },
    formatter: {
      type: Function,
      default: null,
    },
    zeroLabel: {
      type: String,
      default: 'Weiter'
    },
    allowZero: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
    append(n) {
      this.input = this.input + n
    },
    backspace() {
      this.input = this.input.substring(0, this.input.length - 1)
    },
    emitInput() {
      this.$emit('widget-event', { type: 'number-input', questionUid: this.uid, optionUid: this.input })
    },
    next() {
      if(!this.inputValid) {
        return
      }
      this.emitInput()
    },
  },
  computed: {
    inputValid() {
      if(this.allowZero) {
        return true
      }
      return this.input.trim() != ''
    },
    formattedInput() {
      if(this.formatter != null) {
        return this.formatter(this.input)
      }
      return this.input
    },
    buttonLabel() {
      if(!this.input) {
        return this.$t('general.continue')
      }
      return this.$t('general.continue')
    }
  },
  watch: {
    question() {
      this.input = ''
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.number-input-container {
  // padding: 20px 0px;
}

.input-container {
  padding: 10px;
  margin-bottom: 50px;
}

.dummy-input {
  background-color: white;
  border-radius: 0;
  color: $primary;
  text-align: right;
  height: 70px;
  line-height: 70px;
  padding: 0px 15px;
}

.numpad {
  background-color: white;
  color: $primary;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.row {
  display: flex;
}

.element {
  display: inline-block;
  flex: 1;
  border: 1px solid $primary;
  height: 60px;
  line-height: 60px;
}

.proceed {
  font-size: 16px;
  background-color: $yellow;
}
</style>
