<template>
  <div class="text-input-container">
    <div class="corners input-container">
      <input
        :placeholder="question.placeholder"
        v-model="input"
        class="input"
      >
    </div>
    <p
      v-if="question.buttonLabel"
      class="control"
    >
      <span class="button primary">{{ question.buttonLabel }}</span>
    </p>
    <div class="next">
      <div 
        class="button primary" 
        @click="next()"
        :disabled="input.trim().length == 0"
      >
        {{ $t('general.continue')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    uid: {
      type: String,
      required: true
    },
    question: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
    emitInput() {
      if(this.input != '')
        this.$emit('widget-event', { type: 'text-input', questionUid: this.uid, optionUid: this.input })
    },
    next() {
      this.emitInput()
    },
  },
  watch: {
    question() {
      this.input = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.text-input-container {
  width: 300px;
  margin: auto;
  padding: 20px 0px;
}

.input-container {
  margin-bottom: 50px;
}

.input {
  border: none;
  border-radius: 0;
  background-color: white;
  color: $primary;
  text-align: right;
}
.corners {
  padding: 10px;
}
</style>
