<template>
  <div class="range-input-container">
    <div class="scroll-columns">
      <div class="left">Von</div>
      <div class="right">Bis</div>
    </div>
    <div
     class="unit"
     v-if="question.rangeWidget.unit"
    >
      ({{ question.rangeWidget.unit }})
    </div>
    <div class="scroll-columns">
      <div class="left">
        <scroll-picker
          :options="scrollOptions"
          v-model="min"
        /> 
      </div>
      <div class="right">
        <scroll-picker
          :options="scrollOptions"
          v-model="max"
        /> 
      </div>
    </div>
    <div class="next">
      <div 
        class="button primary" 
        @click="next()"
      >
        {{ $t('general.continue')}}
      </div>
    </div>
  </div>
</template>

<script>
import ScrollPicker from './scroll-picker/ScrollPicker.js'

export default {
  name: 'RangeInput',
  components: {
    ScrollPicker
  },
  props: {
    question: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      min: 0,
      max: 0,
    }
  },
  methods: {
    next() {
      let selectedRange = []
      for(let i = this.min; i <= this.max; i += +this.question.rangeWidget.stepSize) {
        selectedRange.push(i)
      }
      this.$emit('widget-event', { type: 'range-input', questionUid: this.question.uid, optionUid: selectedRange.join(',') })
    },
  },
  computed: {
    scrollOptions() {
      let options = []

      let decimals = this.question.rangeWidget.decimals
      if (decimals == undefined)
        decimals = 0

      for(let i = +this.question.rangeWidget.startValue; i <= +this.question.rangeWidget.endValue; i += +this.question.rangeWidget.stepSize) {
        let r = i.toFixed(decimals)
        options.push({ "name": r, "value": i })
      }
      return options
    },
  },
  watch: {
    question: {
      immediate: true,
      handler() {
        this.selectedOption = ''
        this.min = this.question.rangeWidget.startValue
        this.max = this.question.rangeWidget.endValue - this.question.rangeWidget.endValue % this.question.rangeWidget.stepSize
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/shared.scss';

.range-input-container {
  font-size: 30px;
  font-weight: bold;
  // width: 300px;
  // max-width: 70vw;
  // height: 300px;
  margin: auto;
  width: 100%;
}

.unit {
  opacity: 0.7;
  margin: 10px 0px;
}

.scroll-columns {
  display: flex;

  .left, .right {
    flex: 1;
  }
  .left {
    margin-right: 5px;
  }
  .right {
    margin-left: 5px;
  }
}

.vue-scroll-picker-item-placeholder.-selected {
  color: $highlighted;
}
.vue-scroll-picker-item.-selected {
  color: $highlighted;
}
.vue-scroll-picker-layer {

  .top {
    background: linear-gradient(180deg, $primary 10%, rgba($primary, 0.7));
    border-bottom: none;
  }
  .bottom {
    background: linear-gradient(180deg, rgba($primary, 0.7) 10%, $primary);
    border-top: none;
  }
  .middle {
    @include corners();
  }
}
</style>
